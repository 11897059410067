<template>
  <div>

    <div>

      <b-card-actions title="Filters" action-collapse>
        <b-row>

          <b-col md="3" lg="3" xs="12">
            <v-select
                v-model="filterProjectId"
                :options="filterProjectIdOption"
                v-on:input="filterProject"
                :reduce="(task) => task.id"
                label="name"
                placeholder="Select Project"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-col>

          <b-col md="2" lg="2" xs="12">
            <v-select
                v-model="filterBoardListMilestoneId"
                :options="filterMilestoneBoardIdOption"
                :reduce="(item) => item.id"
                label="name"
                placeholder="Select Milestone Board"
                v-on:input="filterMilestone"
                class="custom-font"
            />
          </b-col>

          <b-col md="2" lg="2" xs="12">
            <v-select
                v-model="filterAllMemberSupervisorId"
                :options="filterAllUserIdOption"
                v-on:input="filterSupervisor"
                :reduce="(member) => member.id"
                label="name"
                placeholder="Select Supervisor"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            >
              <template #option="data">
                <UserSelect :user="data" />
              </template>
            </v-select>
          </b-col>

          <b-col md="2" lg="2" xs="12">
            <v-select
                v-model="filterMilestoneStatus"
                :options="milestoneTaskBoardStatusConstants"
                v-on:input="filterStatus"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Select Milestone Status"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-col>

          <b-col md="3" lg="3" xs="12">

            <flat-pickr
                v-model="filterDeadline"
                v-on:input="filterDeadlineSet"
                class="form-control"
                placeholder="Select Milestone Deadline"
                :config="{ mode: 'range'}"
            />

          </b-col>

        </b-row>
      </b-card-actions>



    </div>

    <b-card>
      <div>
        <!-- search input -->
        <div
            class="custom-search d-flex align-items-center justify-content-between"
        >
          <b-dropdown
              :text="selectedPersonalFilterText"
              :variant="'primary'"
              size="sm"
          >
            <b-dropdown-item ref="personalFilter" v-on:click="fullListShow">All</b-dropdown-item>
            <b-dropdown-item ref="personalFilter" v-on:click="imMySupervision">My Supervision</b-dropdown-item>
            <b-dropdown-item ref="personalFilter" v-on:click="assignedInTeam">Assigned in Team</b-dropdown-item>
          </b-dropdown>

          <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
          >
            <template
                v-if="$permissionAbility(MILESTONE_BOARD_CREATE, permissions)"
            >
              <b-button
                  class="flex-shrink-0 mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showModal"
              >
                Create
              </b-button>
            </template>
            <!-- <template>
              <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="goToTrash"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template> -->
          </div>
        </div>

        <!-- table -->
        <vue-good-table
            mode="remote"
            styleClass="vgt-table table-custom-style striped"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
            :columns="columns"
            :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >

          <template slot="table-row" slot-scope="props">
            <template>
              <!-- format_members -->
              <template v-if="props?.column?.field === 'format_title'">
                <div class="table-cell-margin">
                  <template>
                    <div>
                      <b-link :href="getPageUrl(props.row.id)" class="font-weight-bold" style="color:black"
                      >{{ props?.row?.title }}
                      </b-link>
                    </div>
                    <div>
                      <b-badge pill variant="light-primary">{{
                          setMilestoneBoardType(props?.row?.type)
                        }}</b-badge>
                    </div>

                  <div v-if="props?.row?.milestone?.data?.title">
                      Milestone:
                  <span class="font-weight-bold"
                  > {{ props?.row?.milestone?.data?.title }}
                    <b-badge pill variant="light-primary">{{
                        formatMilestoneType(props?.row?.milestone?.data?.is_extra)
                      }}</b-badge></span
                  >
                    </div>
                    <div v-else>
                  <span>Milestone:
                    <b-badge pill variant="light-danger">N/A</b-badge></span
                  >
                    </div>

                    <div
                        v-if="props?.row?.milestone?.data?.payment_type"
                    >
                      Payment Type:
                      <b-badge pill variant="light-primary">{{
                          formatType(props?.row?.milestone?.data?.payment_type)
                        }}</b-badge>
                    </div>


                    <div v-if="props?.row?.department">Department:
                      <b-badge pill variant="primary">
                        {{ props?.row?.department?.data?.name }}
                      </b-badge>
                    </div>

                    <div v-else>
                  <span>Department:
                    <b-badge pill variant="light-danger">N/A</b-badge></span
                  >
                    </div>
                  </template>


                </div>
              </template>
              <!-- format_members -->
              <template v-if="props?.column?.field === 'format_members'">
                <div class="table-cell-margin">
                  <div class="mt-1">
                    <div>
                      <span
                          v-for="(member, index) in props.row.team.data"
                          :key="index"
                      >
                        <template v-if="index <= 2">
                          <b-avatar
                              :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                              size="26"
                              style="margin: 1px"
                          /></template>
                      </span>
                      <span v-if="props?.row?.team_count > 3">
                        <b-link
                            v-on:click="
                            showAllAddedMemberModal(props?.row?.team?.data)
                          "
                        >
                          + {{ props?.row?.team_count - 3 }} more
                        </b-link>
                      </span>

                      <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle"
                          style="margin-left: 5px"
                          v-on:click="showAddMemberModal(props?.row)"
                      >
                        <feather-icon icon="UserPlusIcon" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="props?.column?.field === 'format_status'">
                <div class="mt-1">
                  <b-dropdown
                      :text="setStatus(props?.row?.status)"
                      :variant="setStatusVariant(props?.row?.status)"
                      size="sm"
                      :disabled="
                        !$permissionAbility(
                          MILESTONE_BOARD_UPDATE_STATUS,
                          permissions
                        )
                      "
                  >
                    <b-dropdown-item
                        v-for="item in milestoneTaskBoardStatusConstants"
                        :key="item?.value"
                        v-on:click="updateStatus(props?.row, item)"
                    >
                      {{ item?.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>


              <template v-if="props?.column?.field === 'format_deadline'">
                <span class="font-weight-bold" v-if="props?.row?.milestone">
                  {{ formatDate(props?.row?.milestone?.data?.date) }}
                </span>
                <span v-else>
                  <b-badge pill variant="light-danger">N/A</b-badge>
                  </span>
              </template>

              <!-- supervisor info -->
              <template v-if="props?.column?.field === 'format_supervisor'">
                <span v-if="props?.row?.supervisor">
                  <UserCard :user="props?.row?.supervisor?.data" />
                </span>
                <span v-else>
                  <b-badge variant="light-danger">
                    Supervisor Assigned
                  </b-badge>
                </span>
              </template>

              <template v-if="props?.column?.field === 'format_project'">
                <span class="font-weight-bold" v-if="props?.row?.milestone?.data?.project">
                  {{ props?.row?.milestone?.data?.project?.data?.title }}
                </span>
                <span v-else>
                  <b-badge pill variant="light-warning">Project Less</b-badge>
                </span>
              </template>
              <!-- Task & Subtask Count -->
              <template
                  v-if="props?.column?.field === 'format_task_subtask_count'"
              >
                <div class="mt-1 mb-1">
                  <b>Task: </b>
                  <b-badge pill variant="light-primary">{{
                      props?.row?.tasks_count ? props?.row?.tasks_count : 0
                    }}</b-badge>
                </div>

                <div class="mt-1">
                  <b>SubTask: </b>

                  <b-badge pill variant="light-primary">{{
                      props?.row?.sub_tasks_count
                          ? props?.row?.sub_tasks_count
                          : 0
                    }}</b-badge>
                </div>
              </template>
              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">

                <feather-icon
                    v-on:click="onShowPage(props?.row?.id)"
                    v-b-tooltip.hover
                    icon="EyeIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Show"
                    size="16"
                />

                <feather-icon
                    v-if="$permissionAbility(MILESTONE_BOARD_EDIT, permissions) ||
                        authUser?.id === props?.row?.supervisor?.data?.id"
                    v-on:click="onShow(props.row)"
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                />

                <feather-icon
                    v-if="$permissionAbility(MILESTONE_BOARD_DELETE, permissions) ||
                        authUser?.id === props?.row?.supervisor?.data?.id"
                    v-on:click="onDelete(props.row)"
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10','50', '100', '200']"
                    class="mx-1"
                    @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
          id="modal-add-member-form"
          centered
          title="Add Member"
          hide-footer
          @hidden="hiddenAddMemberModal"
          size="lg"
          no-close-on-backdrop
      >
        <validation-observer ref="addMemberValidation">
          <b-form v-on:submit.prevent="addMemberValidationForm">
            <!-- select member-->
            <b-form-group label="Member" label-for="user_id">
              <ValidationProvider
                  name="user_id"
                  v-slot="{ errors }"
                  vid="user_id"
              >
                <v-select
                    id="user_id"
                    placeholder="Search by employee name or email"
                    v-model="selectMemberIds"
                    :options="memberIdOptions"
                    :reduce="(item) => item?.id"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                >
                  <template #option="{ name, avatar }">
                    <span>
                      <b-avatar :src="avatar === '' ? '/avatar.svg' : avatar" size="26" class="mr-1" />{{
                        name
                      }}</span
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >
                Add
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
          id="modal-all-added-member-form"
          centered
          title="Assigned Members"
          hide-footer
          @hidden="hiddenAllAddedMemberModal"
          no-close-on-backdrop
      >
        <ul>
          <li v-for="(member, index) in assignedMember" :key="index">
            <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px" />
            {{ member.name }}
          </li>
        </ul>
      </b-modal>

      <b-modal
          id="modal-task-board-form"
          centered
          :title="
          modelType == 'editModel'
            ? 'Edit Board Information '
            : 'Create New Task Board'
        "
          hide-footer
          @hidden="hiddenModal"
          no-close-on-backdrop
      >
        <validation-observer ref="milestoneTaskBoardValidation">
          <b-form v-on:submit.prevent="milestoneTaskBoardValidationForm">
            <!-- select milestone-->

            <div class="mb-1" v-if="modelType == ''">
              <b-form-checkbox
                  v-model="isExistingMilestoneSelected"
                  @input="resetModalFields"
              >
                From Existing Milestone
              </b-form-checkbox>
            </div>

            <template v-if="modelType == ''">
              <template v-if="isExistingMilestoneSelected">
                <b-form-group
                    label="Milestone "
                    label-for="milestone_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="milestone "
                      v-slot="{ errors }"
                      vid="milestone_id"
                      rules="required"
                  >
                    <v-select
                        id="milestone_id"
                        placeholder="Choose A Milestone"
                        v-model="milestoneId"
                        :options="milestoneIdOption"
                        :reduce="(item) => item?.id"
                        v-on:input="setMilestoneBoardTitle(milestoneId)"
                        label="name"
                        :disabled="modelType == 'editModel' ? true : false"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                    label="Board Title"
                    label-for="title"
                    class="required-label"
                >
                  <ValidationProvider
                      name="title "
                      v-slot="{ errors }"
                      vid="title"
                      rules="required"
                  >
                    <b-form-input
                        id="title"
                        type="text"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Board Name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group
                    label="Board Title"
                    label-for="title"
                    class="required-label"
                >
                  <ValidationProvider
                      name="title "
                      v-slot="{ errors }"
                      vid="title"
                      rules="required"
                  >
                    <b-form-input
                        id="title"
                        type="text"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Board Name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </template>
            </template>
            <template v-else>
              <template v-if="milestoneId">
                <b-form-group
                    label="Milestone "
                    label-for="milestone_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="milestone "
                      v-slot="{ errors }"
                      vid="milestone_id"
                      rules="required"
                  >
                    <v-select
                        id="milestone_id"
                        placeholder="Choose A Milestone"
                        v-model="milestoneId"
                        :options="milestoneIdOption"
                        :reduce="(item) => item.id"
                        label="name"
                        :disabled="modelType == 'editModel' ? true : false"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group
                    label="Board Title "
                    label-for="title"
                    class="required-label"
                >
                  <ValidationProvider
                      name="title "
                      v-slot="{ errors }"
                      vid="title"
                      rules="required"
                  >
                    <b-form-input
                        id="title"
                        type="text"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Board Name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </template>
            </template>

            <!-- select supervisor-->
            <b-form-group label="Supervisor" label-for="supervisor_id">
              <ValidationProvider
                  name="supervisor"
                  v-slot="{ errors }"
                  vid="supervisor_id"
              >
                <v-select
                    id="supervisor_id"
                    placeholder="Choose a Supervisor"
                    v-model="selectSupervisorId"
                    :options="supervisorIdOption"
                    :reduce="(country) => country.id"
                    label="name"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
            <!-- loading button -->
            <template v-if="isLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import {VueGoodTable} from "vue-good-table";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {mapGetters} from "vuex";
import {
  MILESTONE_BOARD_CREATE,
  MILESTONE_BOARD_DELETE,
  MILESTONE_BOARD_EDIT,
  MILESTONE_BOARD_SEE_ALL,
  MILESTONE_BOARD_UPDATE_STATUS,
} from "@/helpers/permissionsConstant"
import LvColorPicker from "lightvue/color-picker";
import {milestoneTaskBoardStatusConstants} from "@/helpers/constant/milestoneTaskBoardStatusConstant.js";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'
import {
  milestoneBoardTypeConstants as milestoneBoardTypeConstant
} from "@/helpers/constant/milestoneBoardTypeConstant.js";
import UserSelect from '@/layouts/components/UserSelect.vue'
import UserCard from '@/layouts/components/UserCard.vue'
import {formatDateRange} from '@/helpers/helpers'
import { required, max, size, ext, max_value, min_value } from "@validations";

export default {
  name: "MilestoneTaskBoardListView",
  components: {
    UserCard,
    UserSelect,
    LvColorPicker,
    BForm,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BBadge,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    BFormRadio,
    BFormCheckbox,
    flatPickr,
    BCardActions
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      MILESTONE_BOARD_CREATE,
      MILESTONE_BOARD_SEE_ALL,
      MILESTONE_BOARD_EDIT,
      MILESTONE_BOARD_DELETE,
      MILESTONE_BOARD_UPDATE_STATUS,
      authUserId: "",

      filterProjectIdOption: [],
      filterProjectId: "",

      selectedPersonalFilterText: "All",

      filterDeadline: "",

      modelType: "",
      //Members
      isSearchingMember: false,
      searchMember: "",
      selectMemberIds: [],
      searchMemberIdOptions: [],
      memberIdOptions: [],
      isExistingMilestoneSelected: false,
      // Filter Option
      searchTerm: "",
      filterSuperVisorId: "",
      filterAllMemberSupervisorId: "",
      filterMemberId: "",
      filterMilestoneStatus: "",
      filterInTeamId: "",
      filterBoardListMilestoneId: "",
      filterDepartmentId: "",
      filterStartDate: "",
      filterEndDate: "",

      // Assigned Member
      assignedMember: [],

      //Board
      title: "",
      milestoneTaskBoardStatusConstants,
      milestoneBoardTypeConstant,
      firsRowId: "",
      boardId: "",
      title: "",
      type: "",
      milestoneId: "",
      milestoneIdOption: [],
      departmentIdOption: [],
      filterMilestoneBoardIdOption: [],
      filterAllUserIdOption: [],
      selectSupervisorId: "",
      supervisorIdOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Project",
          field: "format_project",
          sortable: false,
        },
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Supervisor",
          field: "format_supervisor",
          sortable: false,
        },
        {
          label: "Member",
          field: "format_members",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Task & SubTask Count",
          field: "format_task_subtask_count",
          sortable: false,
        },
        {
          label: "Deadline",
          field: "format_deadline",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      allMilestones: [],
      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
      filters: "boardModule/getFilters",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };
      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const [allMilestones, departments, allMembers] = await Promise.all([
        this.getAllMilestones(),
        this.getDepartments(),
        this.getAllUsers(),
      ]);

      this.allMilestones = allMilestones?.data?.data;

      this.milestoneIdOption = (allMilestones?.data?.data).map((item) => {
        let name = item.title;

        return {
          name,
          id: item.id,
        };
      });

      // Departments
      this.departmentIdOption = (departments?.data?.data || []).map((item) => {
        let name = item.name;
        return {
          name,
          id: item.id,
        };
      });

      //filter member
      this.filterAllUserIdOption = (allMembers?.data?.data || []).map(
          (item) => {
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }
          }
      );

      this.supervisorIdOption = (allMembers?.data?.data || []).map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });
      this.memberIdOptions = (allMembers?.data?.data).map((item) => {
        let name = item?.name;

        if (item?.employee_number) {
          name = `${item?.name}  (${item?.employee_number})`;
        }
        return {
          name,
          avatar: item?.avatar,
          id: item.id,
        };
      });

      //call all milestone api, if department is selected then don't call milestone board api
      await this.setMilestoneBoardInfo();
      //get project approved options
      await this.getApprovedProjects();

      this.filterProjectId = this.filters?.filterProjectId
      this.filterBoardListMilestoneId = this.filters?.filterBoardListMilestoneId
      this.filterMilestoneStatus = this.filters?.filterMilestoneStatus

      this.filterSupervisorId = this.filters?.filterSupervisorId
      this.filterDeadline = this.filters?.filterDeadline
      this.filterAllMemberSupervisorId = this.filters?.filterAllMemberSupervisorId


      if (this.filterProjectId) {
        this.projectWiseMilestone()
      }

      this.loadItems()

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {


    filterMilestone(){
      this.$store.dispatch("boardModule/setFilter", {
        key: "filterBoardListMilestoneId",
        value: this.filterBoardListMilestoneId,
      });

      console.log(this.filter?.filterBoardListMilestoneId)

      this.loadItems()
    },

    filterSupervisor(){
      this.$store.dispatch("boardModule/setFilter", {
        key: "filterAllMemberSupervisorId",
        value: this.filterAllMemberSupervisorId,
      });

      this.loadItems()
    },

    filterStatus(){
      this.$store.dispatch("boardModule/setFilter", {
        key: "filterMilestoneStatus",
        value: this.filterMilestoneStatus,
      });

      this.loadItems()
    },

    filterDeadlineSet() {
      this.$store.dispatch("boardModule/setFilter", {
        key: "filterDeadline",
        value: this.filterDeadline,
      });

      this.loadItems()
    },

    async filterProject() {

      this.filterBoardListMilestoneId = ''

      this.$store.dispatch("boardModule/setFilter", {
        key: "filterProjectId",
        value: this.filterProjectId,
      });

      this.$store.dispatch("boardModule/setFilter", {
        key: "filterBoardListMilestoneId",
        value: this.filterBoardListMilestoneId,
      });

      if (this.filterProjectId) {
        this.projectWiseMilestone()
      }
      await this.loadItems()
    },

    async projectWiseMilestone(){
      const [allMilestoneBoard] = await Promise.all([
        this.$api.get(`api/milestone-task-boards/all/boards?include=milestone`),
      ]);

      this.filterMilestoneBoardIdOption = (
          allMilestoneBoard?.data?.data || []
      ).filter(item => item.milestone?.data?.project_id === this.filterProjectId)
          .map((item) => {
            let name = item.title;
            return {
              name,
              id: item.id,
            };
          });

    },

    async getApprovedProjects() {
      const [allApprovedProjects] = await Promise.all([
        this.$api.get(`api/projects/approve`),
      ]);

      this.filterProjectIdOption = (
          allApprovedProjects?.data?.data || []
      ).map((item) => {
        let name = item.lead.title;
        return {
          name,
          id: item.id,
        };
      });

    },

    setMilestoneBoardTitle(milestoneId) {
      const selectedMilestone = this.allMilestones.find(
          (item) => item.id === milestoneId
      );

      this.title = selectedMilestone?.title;
    },
    setMilestoneBoardType(value) {
      const boardType = milestoneBoardTypeConstant.find(
          (item) => item?.value === value
      );

      if (boardType) {
        return boardType?.name;
      } else {
        return "";
      }
    },
    setStatus(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
          (item) => item?.value == status
      );

      return statusType?.name;
    },

    setStatusVariant(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
          (item) => item?.value == status
      );

      return statusType?.variant;
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-task-board-details",
        params: { id },
      });
    },
    getPageUrl(id) {
      return this.$router.resolve({ name: "admin-task-board-details", params: { id } }).href;
    },
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    formatType(value) {
      if (value) {
        return value;
      }
      return "N/A";
    },
    goToTrash() {
      this.$router.push({ name: "admin-task-board-trash" });
    },
    showModal() {
      this.$bvModal.show("modal-task-board-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-task-board-form");
      this.resetModal();
    },

    resetModalFields() {
      this.modelType = "";
      this.boardId = "";
      this.milestoneId = "";
      this.title = "";
      this.selectSupervisorId = "";
    },
    resetModal() {
      this.resetModalFields();
      this.isExistingMilestoneSelected = false;
    },
    showAddMemberModal(value) {
      this.boardId = value?.id;
      this.selectMemberIds = (value?.team?.data).map((item) => item?.id);

      this.$bvModal.show("modal-add-member-form");
    },

    hiddenAddMemberModal() {
      this.$bvModal.hide("modal-add-member-form");
      this.resetAddMemberModal();
    },

    showAllAddedMemberModal(value) {
      this.assignedMember = value.map((item) => {
        let name = item?.name;
        if (item?.employee_number) {
          name = `${item?.name}  (${item?.employee_number})`;
        }

        return {
          name,
          id: item.id,
          avatar: item?.avatar,
        };
      });

      this.$bvModal.show("modal-all-added-member-form");
    },
    hiddenAllAddedMemberModal() {
      this.$bvModal.hide("modal-all-added-member-form");
    },
    resetAddMemberModal() {
      this.boardId = "";
      this.selectMemberIds = [];
    },
    onShow(value) {
      this.modelType = "editModel";
      this.boardId = value?.id;
      this.title = value?.milestone_id ? "" : value?.title;
      if (!this.title) {
        this.milestoneId = value?.milestone_id ? +value?.milestone_id : "";
      } else {
        this.milestoneId = "";
      }
      this.type = value?.type;
      this.selectSupervisorId = value?.supervisor_id
          ? +value?.supervisor_id
          : "";

      this.showModal();
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;

        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    filterMilestoneTaskBoardByDate() {
      this.loadItems();
    },

    resetFilterLeadsByDate() {
      this.filterStartDate = "";
      this.filterEndDate = "";
      this.loadItems();
    },

    // show all
    fullListShow(e) {
      this.selectedPersonalFilterText = e.target.innerText
      this.filterSuperVisorId = null;
      this.filterInTeamId = null;
      this.loadItems();
    },

    //in my supervision
    imMySupervision(e) {
      this.selectedPersonalFilterText = e.target.innerText
      this.filterSuperVisorId = this.authUser.id;
      this.filterInTeamId = null;
      this.loadItems();
    },

    // in my team
    assignedInTeam(e) {
      this.selectedPersonalFilterText = e.target.innerText
      this.filterInTeamId = this.authUser.id;
      this.filterSuperVisorId = null;
      this.loadItems();
    },

    // Department wise milestone board
    async setDepartmentWiseInfo(deptId) {
      this.loadItems();

      this.filterBoardListMilestoneId = "";
      const [departmentMilestones] = await Promise.all([
        this.getDepartmentWiseMilestones(deptId),
      ]);

      // filter milestone board
      this.filterMilestoneBoardIdOption = (
          departmentMilestones?.data?.data?.milestoneBoards?.data || []
      ).map((item) => {
        let name = item.title;
        return {
          name,
          id: item.id,
        };
      });

      // filter milestone board
      // this.setMilestoneBoardInfo(deptId, departmentMilestones);
    },

    async setMilestoneBoardInfo(deptId, departmentMilestones) {

      if(deptId)
      {
        try {
          this.filterMilestoneBoardIdOption = (
              departmentMilestones?.data?.data?.milestoneBoards?.data || []
          ).map((item) => {
            let name = item.title;
            return {
              name,
              id: item.id,
            };
          });


        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }else{

        try {
          const [allMilestoneBoard] = await Promise.all([
            this.$api.get(`api/milestone-task-boards/all/boards`),
          ]);

          this.filterMilestoneBoardIdOption = (
              allMilestoneBoard?.data?.data || []
          ).map((item) => {
            let name = item.title;
            return {
              name,
              id: item.id,
            };
          });

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },

    async updateStatus(board, item) {
      if (board?.status != item?.value) {
        this.$swal({
          title: "Warning!",
          text: "Are You Sure You Want To Update Status Of This Board?",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$api.put(
                  `/api/milestone-task-boards/${board?.id}/update-status`,
                  {
                    status: item?.value,
                  }
              );
              this.loadItems();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Board status successfully updated",
                },
              });
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
          }
        });
      }
    },
    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To Delete ${row.title}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post(
                "api/milestone-task-boards/force-delete",

                {
                  id: row.id,
                }
            );
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Board successfully deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getActiveUserWithSearch(params) {
      return await this.$api.get("api/users/active-with-search", {
        params: {
          q: params.q,
        },
      });
    },

    async getBoardList(params) {
      return await this.$api.get(
          "api/milestone-task-boards?include=milestone,milestone.project,supervisor,tasks,team, department",
          {
            params: {
              show: params.show,
              page: params.page,
              sort: params.sort,
              q: params.q,
              filterSuperVisorId: params.filterSuperVisorId,
              filterInTeamId: params.filterInTeamId,
              filterProjectId: params.filterProjectId,
              filterDepartmentId: params.filterDepartmentId,
              filterBoardListMilestoneId: params.filterBoardListMilestoneId,
              filterAllMemberSupervisorId: params.filterAllMemberSupervisorId,
              filterMemberId: params.filterMemberId,
              filterMilestoneStatus: params.filterMilestoneStatus,
              filterStartDate: params.filterStartDate,
              filterEndDate: params.filterEndDate,
            },
          }
      );
    },

    async getAllMilestones() {
      return await this.$api.get("api/milestones/all");
    },

    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },

    async getDepartmentWiseMilestones(deptId) {
      if (deptId) {
        return await this.$api.get(
            `api/departments/${deptId}?include=milestoneBoards`
        );
      }
    },

    async getAllUsers() {
      return await this.$api.get(`api/users/active-all`);
    },

    loadSelectedMember() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedMilestoneStatus() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async loadItems() {
      try {

        this.isLoading = true

        const { startDate, endDate } = formatDateRange(this.filterDeadline)

        const [boardList] = await Promise.all([
          this.getBoardList({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterSuperVisorId: this.filterSuperVisorId,
            filterInTeamId: this.filterInTeamId,
            filterProjectId: this.filterProjectId,
            filterDepartmentId: this.filterDepartmentId,
            filterBoardListMilestoneId: this.filterBoardListMilestoneId,
            filterAllMemberSupervisorId: this.filterAllMemberSupervisorId,
            filterMemberId: this.filterMemberId,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterStartDate: startDate,
            filterEndDate: endDate,
          }),
        ]);

        this.rows = boardList?.data?.data;

        const meta = boardList?.data?.meta
        this.totalRecords = meta?.pagination?.total

        this.isLoading = false

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async updateLeadStatus() {
      this.isLoading = true;
      await this.$api.put(`/api/lead-status/${this.leadStatusId}`, {
        name: this.name,
        color_name: this.selectColorValue.name,
        color_code: this.selectColorValue.value,
        status: this.selectStatusValue,
        sequence: this.selectSequence,
        type: this.selectStatusTypeValue,
      });
      this.isLoading = false;
      this.loadItems();
      this.hiddenModal();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          icon: "BellIcon",
          variant: "success",
          text: "Lead Status successfully updated",
        },
      });
    },
    milestoneTaskBoardValidationForm: async function () {
      this.$refs.milestoneTaskBoardValidation
          .validate()
          .then(async (success) => {
            if (success) {
              try {
                this.$refs.milestoneTaskBoardValidation.reset();
                if (this.modelType == "editModel") {
                  this.isLoading = true;
                  // this.title

                  await this.$api.put(
                      `/api/milestone-task-boards/${this.boardId}`,
                      {
                        title: this.title,
                        supervisor_id: this.selectSupervisorId,
                      }
                  );
                  this.isLoading = false;
                  this.loadItems();
                  this.hiddenModal();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Board Information Updated Successfully",
                    },
                  });
                } else {
                  this.isLoading = true;
                  await this.$api.post("/api/milestone-task-boards", {
                    milestone_id: this.milestoneId,
                    title: this.title,
                    supervisor_id: this.selectSupervisorId,
                  });
                  this.isLoading = false;
                  this.loadItems();
                  this.hiddenModal();
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "New Board Created Successfully",
                    },
                  });
                }
              } catch (error) {
                this.isLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }
                if (error?.response?.data?.errors) {
                  this.$refs.milestoneTaskBoardValidation.setErrors(
                      error?.response?.data?.errors
                  );
                }
              }
            }
          });
    },

    addMemberValidationForm: async function () {
      this.$refs.addMemberValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.addMemberValidation.reset();
            this.isLoading = true;

            await this.$api.post("/api/milestone-task-boards/assign-team", {
              milestone_task_board_id: this.boardId,
              user_id: this.selectMemberIds,
            });
            this.isLoading = false;
            this.loadItems();
            this.hiddenAddMemberModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Member Added Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    loadSelectedMilestoneBoard() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async taskFiltering() {
      this.loadItems()
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    //vertical-align: middle !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}
.second-layer-margin {
  margin-top: 15px;
}

.required-label label::after {
  content: " *";
  color: red;
}
</style>
